.child_A{
    border: 2px solid #3C9637;
    color: #3C9637;
}
.child_B{
    border: 2px solid #AAC83C;
    color: #AAC83C;
}
.child_C{
    border: 2px solid #FCC300;
    color: #FCC300;
}
.child_D{
    border: 2px solid #FF784B;
    color: #FF784B;
}
.child_E{
    border: 2px solid #FF5050;
    color: #FF5050;
}
.child_NA{
    border: 2px solid #c8c4c4;
    color: #c8c4c4;
}

.bgColor_A {
    color:#ffff;
 background-color: #3C9637;
}
.bgColor_B {
    color:#ffff;
    background-color: #AAC83C;
}
.bgColor_C {
    color:#ffff;
    background-color: #FCC300;
}
.bgColor_D {
    color:#ffff;
    background-color: #FF784B;
}
.bgColor_E {
    color:#ffff;
    background-color: #FF5050;
}

.bgColor_NA {
    color:#ffff;
    background-color: #C4C4C4;
}

.customMyArrow{
    color:red;
}