.tablecontainer{
    /* margin-top:60px; */
    margin-left: 100px;
    text-align: center;
    border-collapse: collapse;
    margin-bottom: 100px;  
}
.td1{
    width: 400px;
    height: 70px;
    border-left: 1px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 21px;
    text-align:start;
    color:#757D8A;
}
.td11{
    width: 400px;
    height: 70px;
    border-left: 1px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 21px;
    text-align:start;
    color:#455A64;
}
.td2{
    width: 200px;
    border-left: 1px solid rgba(239, 136, 113, 1);
    font-family: 'Montserrat', sans-serif;  
    font-weight: 500;
    font-size: 25px;
    border-collapse: collapse;
    border-right: 1px solid rgba(239, 136, 113, 1);
}
.td3{
    width: 200px;
    border-left: 1px solid rgba(239, 136, 113, 1);
    font-family: 'Montserrat', sans-serif;  
    font-weight: 500;
    font-size: 18px;
    border-collapse: collapse;
    margin:-25px;
    border-right: 1px solid rgba(239, 136, 113, 1);
} 
.td6{
    width: 200px;
    border-left: 1px solid rgba(239, 136, 113, 1);
    font-family: 'Montserrat', sans-serif;
    color: #9EBF22;
    font-weight: 500;
    font-size: 25px;
    border-right: 1px solid rgba(239, 136, 113, 1);
}
.tdh{
    font-family: 'Montserrat', sans-serif;
    font-style: normal; 
    font-weight: 600;
    font-size: 25px;
    padding-top: 60px;
    margin-left: 50px;
    text-align: left;
    color:#455A64;
    border-right: 1px solid rgba(239, 136, 113, 1);
}
.productNames{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    white-space: nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    width:150px;
    color: black;
    margin-left:20px;
}
.line{
    width: 240px;
    height: 0px;
    border: 1px solid #FF725E;
}
.view{
    position: absolute;
    margin-left: 27px;
    margin-top: 12px;
}
.CartView{
    position: absolute;
    margin-left: 27px;
    margin-top: 6px;
}
.letterView{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    font-size: 16px;
}
.toppanecontainer{
    display: flex ;
    margin-top: 90;
    /* height:50px; */
    justify-content: space-between;  
    align-items: center;    
 }                
.comparename {
    margin-left: 100;
    font-family: 'Montserrat', sans-serif;
    font-size: 30;
    text-align: left;
    font-weight: 600;
    color:#37006E;
}                
.totalcount {
    margin-right: 100px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;    
    font-style: normal;
    font-weight: 500;
 }
 .deleteiconview{
    height:25px;
    width:25px;
 }
.iconView{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    font-size: 16px;
}
.productsView{
    height:100px;
    width:100px;
 } 
.productsView1{
    height:50px;
    width:80px;
 } 
 .productsView2{
    height:50px;
    width:80px;
 } 
.iconView1{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    font-size: 16px;
} 
 .compareProductsmodal{
    display: flex;
    align-items: center;
    justify-content: center;
 }
 .compareProductsPaper{
  background-color:#fff;
    border: 2px solid #000;
    box-shadow:#000 ;
    padding:30px;
    width:250px;
    height:250px;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    border-radius: 20px;
 }
 .successTextView {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    margin-top: 10px;
    color: #455A64;
 } 
 .addView{
    height:100px;
    width:100px;
    background-color: #757D8A;
    margin-left:40px;
    margin-top:10px; 
 }
.addView_bg{
    height:100px;
}
.addText{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    margin-left: 40px;
    margin-top: 18px;
}

 @media screen and (max-width:1200px) {
    .comparename {
    margin-left: 45px;
} 
.totalcount {     
    margin-right: 45px;
}
.tablecontainer{    
    margin-left: 45px;    
}
.td3{
    font-size: 16px;
} 
 }




 @media screen and (max-width:600px) {
    .td1{
        display:flow-root;
        margin-left: 0px;
        width:100px;
        font-size: 13px;
        height:40px;
    }
    .tdh{
      width:100px;
      font-size: 12px;
      padding-top: 10px;
    }
    .line{
        width:100px;
    }
    .tablecontainer{
        width:550px;
        margin-left: 10px;
        /* margin-top:20px; */
    }
    .td2{
        width:70px;
        font-size: 15px;
    }
    .td3{
        font-size: 14px;
    } 
    .td6{
        width:70px;
        font-size: 15px;
    }
    .view{
        margin-left: 27px;
        margin-top: 12px;
    }
    .comparename {
        margin-left:10px;
        font-size: 17;
        text-align: left;
        font-weight: 600;
    }  
    .totalcount {
        font-size: 9px;
        margin-right: 0px;
        text-align: center;
        padding-right: 15px;        
    }  
    .toppanecontainer{
        display: flex ;
        height:50px;
        width:100%;
        justify-content:space-between;      
     }
     .deleteiconview{
        height:15px;
        width:15px;
     }
     .productsView{
        height:50px;
        width:50px;
     } 
     .Names{
        font-weight: 500;
        font-size: 10px;
    } 
    .productsView1{
        height:36px;
        width:54px;
     } 
     .letterView{
        font-size: 8px;
     } 
     .iconView{
        height: 22px;
     }
     .score_bg{
        height: 20px;
        width: 20px;
     }   
 }
