#interactive video{
    width: 300px !important;
    height: 300px !important;
}
.viewport .imgBuffer{
    display: none;
}
.viewport .drawingBuffer{
    display: none;
}
